<script>
export default {
  name: "MenuItem",
  functional: true,
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  render(h, context) {
    const { icon, title } = context.props;
    const vnodes = <div class="flex middle"></div>;

    vnodes.children = [];

    if (icon) {
      if (icon.includes("el-icon")) {
        vnodes.children.push(<i class={[icon, "sub-el-icon"]} />);
      } else {
        vnodes.children.push(
          <svg-icon class={["menu-icon", icon]} icon-class={icon} />
        );
      }
    }

    if (title) {
      vnodes.children.push(<span slot="title">{title}</span>);
    }

    return vnodes;
  },
};
</script>

<style lang="scss" scoped>
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
}

.menu-icon {
  font-size: 1em !important;
  width: 1em !important;
  height: 1em !important;

  &.menu-subitem {
    width: 0.8em !important;
    height: 0.8em !important;
  }
}
</style>
