const notice = {
  state: {
    unReadMessageNum: ''
  },
  mutations: {
    UPDATE_UN_READ_MESSAGE_NUM: (state, unReadNum) => {
      state.unReadMessageNum = unReadNum
    }
  },
  actions: {
    NoticeUnReadMessageNumPut({ commit }, unReadNum) {
      commit('UPDATE_UN_READ_MESSAGE_NUM', unReadNum)
    }
  }
}

export default notice
