var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
      on: { click: _vm.toggle },
    },
    [
      _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
        _vm.collapse
          ? _c(
              "div",
              { staticClass: "sidebar-logo-link" },
              [
                _c("svg-icon", {
                  staticClass: "svg-menu",
                  attrs: { "icon-class": "menu-left-arrow" },
                }),
              ],
              1
            )
          : _c("div", { staticClass: "sidebar-logo-link" }, [
              _c(
                "div",
                { staticClass: "sidebar-box" },
                [
                  _c("span", [_vm._v("橘园艺术后台")]),
                  _c("svg-icon", {
                    staticClass: "svg-menu",
                    attrs: { "icon-class": "menu-right-arrow" },
                  }),
                ],
                1
              ),
            ]),
      ]),
      _c("svg-icon", {
        staticClass: "svg-menu",
        attrs: { "icon-class": "menu-left-arrow" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }