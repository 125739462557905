var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-pagination",
    _vm._g(
      {
        attrs: {
          "current-page": _vm.page,
          "page-size": _vm.size,
          total: _vm.total,
          layout: "sizes, jumper, prev, pager, next",
          background: "",
        },
        on: {
          "update:currentPage": function ($event) {
            _vm.page = $event
          },
          "update:current-page": function ($event) {
            _vm.page = $event
          },
          "update:pageSize": function ($event) {
            _vm.size = $event
          },
          "update:page-size": function ($event) {
            _vm.size = $event
          },
        },
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }