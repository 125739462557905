import Vue from 'vue'

import exportExcel from './exportExcel'

import VueRx from 'vue-rx'
import VuejsClipper from "vuejs-clipper/dist/vuejs-clipper.umd"
import "vuejs-clipper/dist/vuejs-clipper.css"

import encrypt from './encrypt'

import storage from './storage'

import imageUpload from './imageUpload'

Vue.prototype.$exportExcel = exportExcel

Vue.use(VueRx)
Vue.use(VuejsClipper)

Vue.use(encrypt)
Vue.use(storage)
Vue.use(imageUpload)
