<template>
  <div class="avatar-block flex" :class="{ inline: inline }">
    <el-avatar
      v-if="showAvatar"
      :shape="shape"
      :size="size"
      :src="avatar"
      fit="cover"
    ></el-avatar>

    <div
      class="flex column center"
      :style="{
        minHeight: size + 'px',
        marginLeft: (showAvatar ? '10' : '0') + 'px',
        overflow: 'hidden',
      }"
    >
      <div class="flex row between">
        <el-tooltip
          class="item"
          effect="dark"
          :content="name"
          placement="top"
          v-if="
            showTooltip &&
            (name && name.length) > tooltipLength
          "
        >
          <span class="name ellipsis">
            {{ name }}
          </span>
        </el-tooltip>
        <span class="name ellipsis" v-else>
          {{ name }}
        </span>
        <div class="user-tag" v-if="userTag">{{ userTag }}</div>
      </div>
      <span class="desc ellipsis" :style="{ fontSize: descSize }">{{
        desc
      }}</span>
      <span v-if="subdesc" class="desc ellipsis" :style="{ fontSize: descSize }">{{
        subdesc
      }}</span>
    </div>
    <div class="close-product-box" v-if="close"  @click="closeProduct">
      <img src="@/assets/close-product.png" class="product-close" alt=""/>
      <img src="@/assets/close-product-red.png" class="product-close-red" alt=""/>
    </div>
    
  </div>
</template>

<script>
export default {
  name: "MfAvatarBlock",

  props: {
    avatar: {
      type: String | Object,
    },
    showAvatar: {
      type: Boolean,
      default: true,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    tooltipLength: {
      type: Number,
      default: 0,
    },
    shape: {
      type: String,
      default: "circle",
    },
    name: {
      required: true,
      type: String,
    },
    desc: {
      required: true,
      type: String,
    },
    subdesc: {
      required: false,
      type: String,
    },
    size: {
      type: Number,
      default: 45,
    },
    descSize: {
      type: String,
      default: "14px",
    },
    userTag: {
      type: String,
      default: '',
    },
    close:{
      type:Boolean,
      default:false
    },
    productId:{
      type:String,
      default:''
    },
    inline: Boolean,
  },
  data(){
    return {

    }
  },
  created(){
  },
  methods: {
    closeProduct(){
      console.log('删除商品')
      this.$emit('closeProduct',this.productId)
    },
  }
};
</script>

<style lang="scss" scoped>
  .avatar-block{
    position: relative;
    padding: 12px;
    border-radius: 12px;
    cursor: pointer;
    .close-product-box{
      position: absolute;
      right: 2px;
      top:2px;
      width: 18px;
      height: 18px;
      img{
        width: 18px;
        height: 18px;
      }
      .product-close{
        display: none;
      }
      .product-close-red{
        display: none;
      }
    }
    &:hover{
      background-color: rgba(202, 209, 214, .2);
      .product-close{
        display: inline-block;
      }
      .close-product-box{
        &:hover{
          .product-close{
            display: none;
          }
          .product-close-red{
            display: inline-block;
          }
        }
      }
    }
  }
  
.el-avatar {
  flex: 0 0 auto;

  ::v-deep img {
    width: 100%;
    height: 100%;
  }
}

.row {
  flex-direction: row;
  align-items: center;
}

.name {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 18px;
  margin-bottom: 4px;
  flex: 1;
}

.user-tag {
  background-color: #2969E3;
  color: #FFFFFF;
  padding: 4px 8px;
  border-radius: 6px;
  margin-left: 4px;
  //width: 64px;
  font-size: 12px;
}

.desc {
  font-size: 14px;
  color: #a7aaac;
  text-align: left;
  white-space: nowrap;
}
</style>
