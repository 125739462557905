<template>
  <div class="table-view">
    <div class="filter-bar flex between middle">
      <div class="filter">
        <slot name="filter" />
      </div>

      <div class="operation">
        <slot name="operation" />
      </div>
    </div>

    <slot />

    <div class="page-bar flex between middle">
      <div class="page-left">
        <slot name="page-left" />
      </div>

      <div class="page-right" v-if="showPage">
        <slot name="page-right">
          <div class="page">
            <span class="total">共 {{ total }} 条</span>

            <mf-pagination
              :current-page.sync="currentPage"
              :page-size.sync="pageSize"
              :total="total"
              v-on="$listeners"
              @current-change="$emit('change')"
              @size-change="$emit('change')"
            >
            </mf-pagination>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import MfPagination from "@/modules/functional/Pagination";

export default {
  name: "MlTableView",

  props: {
    page: {
      type: Number,
      default: 1,
    },

    size: {
      type: Number,
      default: 10,
    },

    total: {
      type: Number,
      default: 0,
    },

    showPage: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    currentPage: {
      get() {
        return this.page;
      },

      set(val) {
        this.$emit("update:page", val);
      },
    },

    pageSize: {
      get() {
        return this.size;
      },

      set(val) {
        this.$emit("update:size", val);
      },
    },
  },

  components: {
    MfPagination,
  },
};
</script>

<style lang="scss" scoped>
.filter-bar {
  margin-bottom: 15px;
}

.page-bar {
  margin-top: 15px;

  .page-right {
    flex: 1;
    justify-content: flex-end;

    .page {
      margin-left: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .total {
      font-size: 13px;
    }
  }
}
</style>
