import store from '@/store'
// plugAuth
export default function (el, binding) {
  // findUserInfo接口返回的项目标识, 比如"louisv"
  const userType = store.getters.user.merchantIdent.toString();
  // 自定义组件传递的参数,比如传递的是"eslauder"
  const arg = binding.arg;
  // 接口返回的标识和传递的不一致，就删除该元素
  if (arg != userType) {
    removeElement(el);
    console.log("标识不一样被删除了")
  };
}
// 删除dom元素
function removeElement(el) {
  el.style.display = 'none';
  el.parentNode && el.parentNode.removeChild(el);
}
/*
使用方式：标签上使用 v-plugAuth:louisv
 */

/**
 * 如果标签上面同时有v-if判断，不要用v-plugAuth:louisv自定义指令，在if判断里面写入$store.getters.user.merchantIdent == 'louisv'"，否则会出现标签被删除的问题
 *
<div v-if="liveUserInfo.live && $store.getters.user.merchantIdent == 'louisv'" class="set-title">
  旁观码
</div>

或者换成：
<template v-if="liveUserInfo.live">
  <div class="set-title" v-plugAuth:louisv>
    旁观码
  </div>
</template>
 */
