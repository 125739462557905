<template>
  <span>{{ timeText(timedown) }}</span>
</template>

<script>
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

export default {
  name: 'MfTimeDown',

  props: {
    // startTime: String,
    // endTime: String,
    countdown: { type: String, default: '0' }
  },

  data() {
    return {
      timedown: 0,
      interval: ''
    }
  },

  created() {
    // if (!this.startTime || !this.endTime) {
    //   this.text = "";
    // }

    dayjs.extend(duration)

    // const endTime = dayjs(this.endTime, "YYYY-MM-DD HH:mm:ss").valueOf();
    // const startTime = dayjs(this.startTime, "YYYY-MM-DD HH:mm:ss").valueOf();
    // let diffTime = dayjs.duration(endTime - startTime);
    this.timedown = this.countdown < 0 ? 0 : this.countdown

    clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.timedown -= 1

      if (this.timedown > 0) {
        this.$forceUpdate()
      } else {
        this.timedown = 0
        clearInterval(this.interval)
        this.$emit('timedown')
      }
    }, 1000)
  },

  methods: {
    formatTime(val) {
      return ('00' + val).slice(-2)
    },

    timeText(time) {
      // const hours = this.formatTime(parseInt(time / 3600))
      // const minutes = this.formatTime(parseInt((time % 3600) / 60))
      // const seconds = this.formatTime(parseInt((time % 3600) % 60))
      const hours = parseInt(time / 3600)
      const minutes = parseInt((time % 3600) / 60)
      const seconds = parseInt((time % 3600) % 60)
      if (+hours >= 24) {
        const day = parseInt((+hours / 24))
        const hour = parseInt((+hours % 24))
        const hour_text = hour
        return `${day}天${hour_text}小时${minutes}分${seconds}秒`
      } else {
        // return `${hours}:${minutes}:${seconds}`
        if (hours !== 0) {
          return `${hours}小时${minutes}分${seconds}秒`
        } else {
          if (minutes !== 0) {
            return `${minutes}分${seconds}秒`
          } else {
            return `${seconds}秒`
          }
        }
      }
    }
  }
}
</script>

<style>
</style>
