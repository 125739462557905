<template>
    <el-dialog :visible.sync="visible" width="450px" :title="title" :close-on-click-modal="false">
        <el-input v-model.sync="propsVal" :placeholder="placeholder"></el-input>
        <div class="footer text-right">
            <el-button type="text" class="black" @click="visible = false">取消</el-button>
            <el-button :type="comfirmBtnType" size="small" style="border-radius: 12px"
                @click="confirmHandler(propsVal, true)">{{
        confirmButtonText
                }}</el-button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    name: "VerificationInput",
    props: {
        title: String,
        value: Boolean,
        placeholder: String,
        expressCode: {
            type: String | Number,
            default: "",
        },
        confirmButtonText: {
            type: String,
            default: "确 认",
        },
        comfirmBtnType: {
            type: String,
            default: 'primary'
        },
        confirmHandler: Function | Promise,
    },
    data() {
        return {
            propsVal: this.expressCode
        }
    },
    watch: {
        expressCode() {
            this.propsVal = this.expressCode
        }
    },
    computed: {
        visible: {
            get() {
                return this.value;
            },
            set(val) {
                // this.propsVal = ''
                this.$emit("input", val);
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.footer {
    margin-top: 30px;
}
.el-input {
    height: 40px;
}
::v-deep {
    input,
    textarea {
        background: #f8f9fa;
        border-color: #f5f5f5;
        border-radius: 12px;
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

        &:hover,
        &:focus {
            border-color: #ccc;
        }
    }
    .el-input__count {
        background: transparent;
        color: #6e777f;

        .el-input__count-inner {
            background: transparent;
        }
    }
    textarea+.el-input__count {
        line-height: 2;
    }
}
</style>
        