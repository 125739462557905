var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "head-bar" },
    [
      _c(
        "el-breadcrumb",
        { staticClass: "bread", attrs: { separator: "/" } },
        _vm._l(_vm.breads, function (item) {
          return _c("el-breadcrumb-item", { key: item.title }, [
            item.name !== _vm.$route.name
              ? _c(
                  "span",
                  {
                    staticStyle: { cursor: "pointer" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({ path: item.redirect })
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.title))]
                )
              : _c("span", [_vm._v(_vm._s(item.title))]),
          ])
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "flex middle" },
        [
          _c(
            "el-popover",
            {
              attrs: {
                placement: "bottom-end",
                trigger: "hover",
                "visible-arrow": false,
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "el-dropdown-link",
                  attrs: { slot: "reference" },
                  slot: "reference",
                },
                [
                  _c("el-avatar", {
                    attrs: {
                      size: 24,
                      src: _vm.user.avatar,
                      icon: "el-icon-user-solid",
                    },
                  }),
                  _c("i", { staticClass: "el-icon-arrow-down el-icon--right" }),
                ],
                1
              ),
              _c("div", { staticClass: "user-block" }, [
                _c(
                  "div",
                  { staticClass: "user-info" },
                  [
                    _c("mf-avatar-block", {
                      attrs: {
                        avatar: _vm.user.avatar,
                        name: _vm.user.nickName,
                        desc: "账号：" + _vm.user.userName,
                        "desc-size": "12px",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "operation flex average" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            _vm.userVisible = true
                          },
                        },
                      },
                      [_vm._v("账号设置")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "black",
                        attrs: { type: "text" },
                        on: { click: _vm.logout },
                      },
                      [_vm._v("安全退出")]
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "账号设置",
            "close-on-click-modal": false,
            visible: _vm.userVisible,
            "append-to-body": true,
            width: "550px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userVisible = $event
            },
            open: _vm.initForm,
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex between" },
            [
              _c(
                "el-form",
                {
                  ref: "userForm",
                  attrs: { model: _vm.form, rules: _vm.rules },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "昵称" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          placeholder: "请输入昵称",
                          maxlength: 50,
                          "show-word-limit": "",
                          clearable: "",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.form.nickName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "nickName", $$v)
                          },
                          expression: "form.nickName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "当前密码", prop: "oldPassword" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          placeholder: "请输入当前密码",
                          "show-password": "",
                        },
                        model: {
                          value: _vm.form.oldPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "oldPassword", $$v)
                          },
                          expression: "form.oldPassword",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "新密码", prop: "password" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          placeholder: "请输入新密码",
                          "show-password": "",
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    flex: "1",
                    "text-align": "center",
                    "padding-top": "30px",
                  },
                },
                [
                  _c(
                    "mf-uploader",
                    {
                      attrs: { accept: "image/png, image/jpeg" },
                      on: { success: _vm.uploadAvatar },
                    },
                    [
                      _c("el-image", {
                        staticClass: "avatar-cover",
                        class: { "is-upload": this.form.avatar },
                        attrs: {
                          size: 100,
                          fit: "cover",
                          src: _vm.form.avatar,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "black",
                  attrs: { type: "text" },
                  on: {
                    click: function ($event) {
                      _vm.userVisible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updateUserInfo },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }