<template>
  <el-dialog
    :visible.sync="show"
    :title="title"
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-click-modal="false"
  >
    <slot />

    <div slot="footer">
      <slot name="footer" v-if="$slots.footer" />

      <template v-else>
        <el-button type="text" class="black" @click="handleCancel"
          >取消</el-button
        >
        <el-button @click="handleConfirm">确定</el-button>
      </template>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "MfDialog",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    onCancel: Function,

    onConfirm: Function,

    title: String,
  },

  computed: {
    show: {
      get() {
        return this.visible;
      },

      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },

  methods: {
    handleCancel() {
      if (this.onCancel) {
        this.onCancel();
      } else {
        this.show = false;
      }
    },

    handleConfirm() {
      if (this.onConfirm) {
        this.onConfirm();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    border-radius: 16px;
  }

  .el-dialog__header {
    border-bottom: 1px solid #eff0f2;
    font-weight: 18px;
    font-weight: 500;

    .el-dialog__close {
      margin-top: 2px;
      font-size: 20px;
      color: #6e777f;
      font-weight: 600;
    }
  }

  .el-dialog__footer {
    border-top: 1px solid #eff0f2;
  }
}
</style>
