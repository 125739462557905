import store from '@/store'
/**
 * @payAuth 支付权限
 * @operation v-payAuth / v-payAuth:1
 * @payParamsType 1:微信支付 2:快收支付 3:收钱吧支付 4:大额支付（审核） 5:大额支付（无审核）
 * @auth zwh
 * @creatTime 2022.8.19
 * */
export default function (el, binding) {
  // findUserInfo接口返回的支付权限标识
  const userType = store.getters.user.merchantPayChannel.toString();
  // 自定义组件传递的参数,比如传递的是"eslauder"
  const arg = binding.arg;
  if(userType === ''){
    removeElement(el);
    console.log("无支付权限！")
    return
  }
  const payRolesList = userType.split(',')
  // 接口返回的标识和传递的不一致，就删除该元素
  switch (arg) {
    case '1':
      if(!payRolesList.includes(arg)){
        removeElement(el);
        console.log("无微信支付权限！")
      }
      break;
    case '2':
      if(!payRolesList.includes(arg)){
        removeElement(el);
        console.log("无快收支付权限！")
      }
      break;
    case '3':
      if(!payRolesList.includes(arg)){
        removeElement(el);
        console.log("无收钱吧支付权限！")
      }
      break;
    case '4':
      if(!payRolesList.includes(arg)){
        removeElement(el);
        console.log("无大额支付（审核）权限！")
      }
      break;
    case '5':
      if(!payRolesList.includes(arg)){
        removeElement(el);
        console.log("无大额支付（无审核）权限！")
      }
      break;
    default:
      break;
  }

}
// 删除dom元素
function removeElement(el) {
  el.style.display = 'none';
  el.parentNode && el.parentNode.removeChild(el);
}
