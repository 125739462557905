<template>
  <el-input ref="input" v-model="input" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  name: "MfInput",

  props: {
    value: {
      type: String | Number,
      default: "",
    },

    tabIndex: String,
  },

  computed: {
    input: {
      get() {
        return this.value.toString();
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },

  mounted() {
    this.setTabIndex();
  },

  updated() {
    this.setTabIndex();
  },

  methods: {
    setTabIndex() {
      if (this.tabIndex) {
        this.$refs.input.$el.querySelectorAll("input").forEach((item) => {
          this.$nextTick(() => {
            item.setAttribute("tabindex", this.tabIndex);
          });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-input,
.el-textarea {
  width: 344px;

  &.el-input {
    height: 40px;
  }

  ::v-deep {
    input,
    textarea {
      background: #f8f9fa;
      border-color: #f5f5f5;
      border-radius: 12px;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

      &:hover,
      &:focus {
        border-color: #ccc;
      }
    }

    .el-input__count {
      background: transparent;
      // color: #6e777f;

      .el-input__count-inner {
        background: transparent;
      }
    }

    textarea + .el-input__count {
      line-height: 2;
    }
  }
}
</style>
