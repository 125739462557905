<template>
  <el-dialog
    title="裁切图片"
    :visible.sync="showClipper"
    append-to-body
    :close-on-click-modal="false"
    :show-close="false"
  >
    <clipper-basic
      ref="clipper"
      :ratio="ratio"
      :src="src"
      cross-origin="anonymous"
    />

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">放弃裁切</el-button>
      <el-button type="primary" @click="confirmEvent">确定裁切</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "ImageClipper",

  data() {
    return {
      showClipper: false,
      src: "",
      ratio: 1,
      confirm: null,
      cancel: null,
      filename: "",
    };
  },

  methods: {
    confirmEvent() {
      const canvas = this.$refs.clipper.clip();
      const type = "image/jpeg";
      const name = (this.filename || "default")
        .split(".")
        .slice(0, -1)
        .concat("jpg")
        .join(".");
      canvas.toBlob((blob) => {
        const file = new File([blob], name, { type });
        this.confirm(file);
      }, type);
    },

    handleCancel() {
      this.cancel()
    },
  },
};
</script>
