<template>
  <div class="head-bar">
    <el-breadcrumb class="bread" separator="/">
      <el-breadcrumb-item v-for="item in breads" :key="item.title">
        <span
          v-if="item.name !== $route.name"
          @click="$router.push({ path: item.redirect })"
          style="cursor: pointer"
          >{{ item.title }}</span
        >
        <span v-else>{{ item.title }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="flex middle">
      <el-popover placement="bottom-end" trigger="hover" :visible-arrow="false">
        <span class="el-dropdown-link" slot="reference">
          <el-avatar
            :size="24"
            :src="user.avatar"
            icon="el-icon-user-solid"
          ></el-avatar>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>

        <div class="user-block">
          <div class="user-info">
            <mf-avatar-block
              :avatar="user.avatar"
              :name="user.nickName"
              :desc="`账号：${user.userName}`"
              desc-size="12px"
            />
          </div>

          <div class="operation flex average">
            <el-button type="text" @click="userVisible = true"
              >账号设置</el-button
            >

            <el-button type="text" class="black" @click="logout"
              >安全退出</el-button
            >
          </div>
        </div>
      </el-popover>
    </div>

    <el-dialog
      title="账号设置"
      :close-on-click-modal="false"
      :visible.sync="userVisible"
      @open="initForm"
      :append-to-body="true"
      width="550px"
    >
      <div class="flex between">
        <el-form :model="form" :rules="rules" ref="userForm">
          <el-form-item label="昵称">
            <el-input
              v-model="form.nickName"
              placeholder="请输入昵称"
              :maxlength="50"
              show-word-limit
              clearable
              style="width: 300px"
              autocomplete="off"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="当前密码"
            prop="oldPassword"
          >
            <el-input
              v-model="form.oldPassword"
              placeholder="请输入当前密码"
              show-password
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="新密码"
            prop="password"
          >
            <el-input
              v-model="form.password"
              placeholder="请输入新密码"
              show-password
              style="width: 300px"
            ></el-input>
          </el-form-item>
        </el-form>

        <div style="flex: 1; text-align: center; padding-top: 30px">
          <mf-uploader
            accept="image/png, image/jpeg"
            @success="uploadAvatar"
          >
            <el-image
              :size="100"
              class="avatar-cover"
              :class="{ 'is-upload': this.form.avatar }"
              fit="cover"
              :src="form.avatar"
            />
          </mf-uploader>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="text" class="black" @click="userVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="updateUserInfo">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import MfAvatarBlock from "@/modules/functional/AvatarBlock";
import MfUploader from "@/modules/functional/Uploader";
import { editUserProfile } from '@/api/user'

export default {
  name: "Headbar",

  components: {
    MfAvatarBlock,
    MfUploader,
  },

  data() {
    return {
      userVisible: false,
      form: {
        userId: 0,
        nickName: '',
        password: '',
        oldPassword: '',
        avatar: ''
      },
      rules: {
        oldPassword: [{
          validator: (rule, value, callback) => {
            let re =  /^(?![\d]+$)(?![a-zA-Z]+$)(?![-=+_.,]+$)[\da-zA-Z-=+_.,]{6,18}$/
            if (value) {
              if (value.length < 6 || value.length > 18) {
                return callback(new Error("请输入6位至18位密码"))
              }
              if (!re.test(value)) {
                return callback(new Error("密码中需要包含字母、数字、符号其中两种形式"))
              }
            }
            callback()
          },
          trigger: "blur"
        }],
        password: [{
          validator: (rule, value, callback) => {
            let re =  /^(?![\d]+$)(?![a-zA-Z]+$)(?![-=+_.,]+$)[\da-zA-Z-=+_.,]{6,18}$/
            if (value && !this.form.oldPassword ) {
              return callback(new Error("要修改密码，请先输入当前密码"))
            }
            if (value.length < 6 || value.length > 18) {
              return callback(new Error("请输入6位至18位密码"))
            }
            if (!re.test(value)) {
              return callback(new Error("密码中需要包含字母、数字、符号其中两种形式"))
            }
            callback()
          },
          trigger: "blur"
        }]
      },
    };
  },

  computed: {
    breads() {
      return this.$route.matched
      .map((item) => ({ title: item.meta.title, name: item.name, redirect: item.redirect  }))
      .filter((item) => item.title)
    },
    user() {
      return this.$store.state.user.user;
    },
    sidebar() {
      return this.$store.state.app.sidebar;
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch("FedLogOut");
      localStorage.removeItem("username");
      localStorage.removeItem("password");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },

    initForm() {
      Object.assign(this.form, {
        userId: this.user.userId,
        nickName: this.user.nickName,
        avatar: this.user.avatar,
      })
    },

    uploadAvatar(url) {
      this.form.avatar = url
    },

    async updateUserInfo() {
      if (Object.entries(this.form).some(([_, value]) => value)) {
        if (this.form.password || this.form.oldPassword) {
          if (this.form.password && this.form.oldPassword) {
            await editUserProfile(this.form)
            this.$message.success("保存成功");
            this.$store.dispatch("GetUserInfo");
            setTimeout(() => {
              this.$forceUpdate();
            }, 2000);
            return
          }
        }
        await editUserProfile({
          userId: this.form.userId,
          nickName: this.form.nickName,
          avatar: this.form.avatar
        })

        this.$message.success("保存成功");
        this.$store.dispatch("GetUserInfo");
        setTimeout(() => {
          this.$forceUpdate();
        }, 2000);
      }

      this.userVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.head-bar {
  padding: 0 24px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid #F6F6F6;
  ::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    font-weight: 700;
  }

  .el-dropdown-link {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .el-icon-arrow-down {
    color: #000;
    font-size: 10px;
    font-weight: bold;
    margin-left: 8px;
  }
}

.user-block {
  margin: -12px;
  width: 240px;
  z-index: 100;
  position: relative;

  .user-info {
    padding: 18px;
    background: #f2f7ff;
  }

  .operation {
    width: 100%;

    .el-button {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

.avatar-cover {
  position: relative;
  cursor: pointer;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;

  &.is-upload:after {
    display: none;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    background-image: url("~@/assets/upload.png");
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 50%;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

</style>
