import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  try {
    NProgress.start()
    // set page title
    document.title = getPageTitle(to.meta.title)
    // determine whether the user has logged in
    const hasToken = getToken()
    if (hasToken) {
      if (to.path === '/login') {
        next({ path: '/' })
      } else if (whiteList.indexOf(to.path) !== -1) {
        next()
      } else {
        // 拉取用户信息
        if (!store.getters.name) {
          try {
            // 获取用户角色及权限
            await store.dispatch('GetUserInfo');
            // 解析获取所有路由表
            const allRoutes = await store.dispatch('GenerateRoutes')
            router.addRoutes(allRoutes) // 动态添加可访问路由表
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
          } catch (e) {
            console.log(e)
          }
        } else {
          next()
        }
      }
    } else {
      /* has no token*/
      if (whiteList.indexOf(to.path) >= 0) {
        // in the free login whitelist, go directly
        next()
      } else {
        // other pages that do not have permission to access are redirected to the login page.
        next(`/login?redirect=${to.path}`)
      }
    }
  } finally {
    NProgress.done()
  }
  
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
