export default {
  install(Vue) {
    Vue.prototype.$storage = {
      getLocal(key) {
        return localStorage.getItem(key)
      },

      setLocal(key, value) {
        localStorage.setItem(key, value)
      },

      pushLocal(key, value) {
        let old = JSON.parse(localStorage.getItem(key) || '[]')

        old.push(value)

        localStorage.setItem(key, JSON.stringify(old))
      }
    }
  }
}
