<template>
  <el-empty :image="require('@/assets/icon-empty.png')"></el-empty>
</template>

<script>
export default {
  name: "MfEmpty",
};
</script>

<style lang="scss" scoped>
::v-deep .el-empty__image {
  width: 90px;
  height: 90px;
}

::v-deep .el-empty__description {
  margin-top: 10px;
  line-height: 1;
}
</style>
