<template>
  <el-dialog
    :visible.sync="visible"
    width="450px"
    :title="title"
    :close-on-click-modal="false"
  >
    <div style="line-height: 1.5">{{ content }}</div>

    <div class="footer text-right">
      <el-button type="text" class="black" @click="visible = false"
        >取消</el-button
      >
      <el-button :type="comfirmBtnType" size="small" style="border-radius: 12px" @click="confirmHandler">{{
        confirmButtonText
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "MfConfirm",

  props: {
    value: Boolean,
    title: String,
    content: String,
    confirmButtonText: {
      type: String,
      default: "确认",
    },
    confirmHandler: Function | Promise,
    comfirmBtnType: {
      type: String,
      default: 'primary'
    }
  },

  computed: {
    visible: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  margin-top: 30px;
}
</style>
