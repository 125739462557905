<template>
  <el-button v-bind="$attrs" v-on="$listeners">
    <slot />
  </el-button>
</template>

<script>
export default {
  name: "MfFooterButton",
};
</script>

<style lang="scss" scoped>
.el-button {
  height: 35px;
  padding: 10px 20px;
  border-radius: 12px;
  color: #000;
  border-color: #e2e2e2;

  &:hover,
  &:focus {
    background: transparent;
  }
}
</style>
