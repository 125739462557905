import request from '@/utils/request'

// 修改用户信息
export function editUserProfile(data) {
  return request({
    url: '/system/user/profile',
    method: 'put',
    data
  })
}

// 查询小程序用户表
export function getUserList(params) {
  return request({
    url: '/api/v1/appUser/getPage',
    method: 'get',
    params
  })
}
