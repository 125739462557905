<template>
  <div class="full-page edit-page">
    <div :class="{ full: fullPage, bottom: $slots.footer }">
      <slot />
    </div>

    <div class="footer flex right middle" v-if="$slots.footer">
      <slot name="footer" />
    </div>

    <slot name="dialog" />
  </div>
</template>

<script>
export default {
  name: "MlEditPage",

  props: {
    fullPage: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.edit-page {
  position: relative;

  .full {
    height: 100%;
    overflow: hidden;
  }

  .bottom {
    padding-bottom: 64px;
  }

  .footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 20px;
    background: #fff;
    box-shadow: 10px -2px 20px 0px rgba(0, 0, 0, 0.06);
    z-index: 99;

    .el-button + .el-button {
      margin-left: 24px;
    }
  }
}
</style>
